import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

type FloatingMoonProps = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  isVisible: boolean;
};

export const FloatingMoon: React.FC<FloatingMoonProps> = ({ children, className, isVisible, style }) => {
  const controls = useAnimation();

  useEffect(() => {
    if (isVisible) {
      controls
        .start({
          scale: 1,
          opacity: 1,
          transition: { duration: 0.5, ease: 'easeOut' }
        })
        .then(() => {
          controls.start({
            y: [0, -5, 0],
            transition: { duration: 2, ease: 'easeInOut', repeat: Infinity, repeatType: 'mirror' }
          });
        });
    } else {
      controls.start({ scale: 0, opacity: 0 });
    }
  }, [isVisible, controls]);

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <motion.div className={className} style={style} initial={{ scale: 0, opacity: 0 }} animate={controls}>
        {children}
      </motion.div>
    </>
  );
};
