'use client';
import { Column } from '@/components/Column';
import { Row } from '@/components/Row';
import { useScreenSize } from '@/hooks';
import { faLocationDot, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import Image from 'next/legacy/image';
import { FunctionComponent, useEffect, useState } from 'react';

interface Props {
  index: number;
  isSelected?: boolean;
}

const IMAGE_CDN_URL = process.env.NEXT_PUBLIC_IMAGE_CDN_URL;

const items = [
  {
    title: 'Esmaltado semipermanente',
    location: 'Av. Belgrano 2354',
    isLocal: true
  },
  {
    title: 'Diseño y perfilado de cejas',
    location: 'Tucumán 1234',
    isLocal: true
  },
  {
    title: 'Workshop: Maquillaje social',
    location: 'Via Zoom',
    isLocal: false
  }
];

export const ImagesAnimation: FunctionComponent<Props> = ({ index, isSelected = false }) => {
  const [loaded, setLoaded] = useState(false);
  const { isMobile } = useScreenSize();

  const delayBeforeAnimationStarts = isMobile ? 0.3 : 0;

  useEffect(() => {
    let timer: any;

    if (!isMobile) {
      setLoaded(true);
    } else if (isSelected) {
      timer = setTimeout(() => {
        setLoaded(true);
      }, delayBeforeAnimationStarts * 1000);
    } else {
      setLoaded(false);
    }

    return () => clearTimeout(timer);
  }, [isSelected, isMobile]);

  const scaleVariants = {
    hidden: { scale: 0 },
    visible: {
      scale: 1,
      transition: {
        delay: delayBeforeAnimationStarts,
        duration: 0.5
      }
    }
  };

  const firstImageVariants = {
    hidden: { y: '-100px', x: '-100px', opacity: 0 },
    visible: {
      y: '0px',
      x: '0px',
      opacity: 1,
      transition: {
        delay: delayBeforeAnimationStarts,
        duration: 0.5
      }
    }
  };
  const secondImageVariants = {
    hidden: { y: '100px', x: '100px', opacity: 0 },
    visible: {
      y: '0px',
      x: '0px',
      opacity: 1,
      transition: {
        delay: delayBeforeAnimationStarts,
        duration: 0.5
      }
    }
  };

  const box1Variants = {
    hidden: { x: '100px', opacity: 0 },
    visible: {
      x: '0px',
      opacity: 1,
      transition: {
        delay: delayBeforeAnimationStarts,
        duration: 0.5
      }
    }
  };

  const box2Variants = {
    hidden: { x: '-100px', opacity: 0 },
    visible: {
      x: '0px',
      opacity: 1,
      transition: {
        delay: delayBeforeAnimationStarts,
        duration: 0.5
      }
    }
  };

  const box3Variants = {
    hidden: { x: '100px', opacity: 0 },
    visible: {
      x: '0px',
      opacity: 1,
      transition: {
        delay: delayBeforeAnimationStarts,
        duration: 0.5
      }
    }
  };

  const calendarVariants = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        delay: delayBeforeAnimationStarts,
        duration: 0.5
      }
    }
  };

  const Circle = () => (
    <motion.div
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      className="absolute w-[300px] h-[300px] sm:w-[335px] sm:h-[335px] lg:w-[435px] lg:h-[435px] border-[1.5px] border-[#00CDFF] rounded-full opacity-30"
      initial="hidden"
      animate="visible"
      variants={scaleVariants}
      transition={{ duration: 1 }}
    />
  );
  const InnerCircle = () => (
    <motion.div
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      className="absolute w-[250px] h-[250px] sm:w-[300px] sm:h-[300px] lg:w-[350px] lg:h-[350px] border-[1.5px] border-[#00CDFF] rounded-full"
      initial="hidden"
      animate="visible"
      variants={scaleVariants}
      transition={{ duration: 1 }}
    />
  );

  const BoxItem = ({ title, location, color, isLocal }: any) => {
    const isWhiteText = color === 'red';
    return (
      <Column
        gap={4}
        className={classNames({
          'sm:px-4 px-2 py-2 w-[200px] sm:w-[250px] rounded-[4px] shadow-[8px_8px_20px_0px_#C4CAD3]': true,
          'bg-[#FF4658] text-white ': color === 'red',
          'bg-[#0AD6A1]': color === 'green',
          'bg-[#FFE066]': color === 'yellow'
        })}
      >
        <p className="font-medium sm:font-bold sm:text-base text-xs">{title}</p>
        <Row gap={4}>
          <FontAwesomeIcon
            icon={isLocal ? faLocationDot : faVideo}
            width={16}
            height={16}
            color={isWhiteText ? 'white' : 'black'}
          />
          <p className="text-xs">{location}</p>
        </Row>
      </Column>
    );
  };

  switch (index) {
    case 0:
      return (
        <>
          <Circle />
          <InnerCircle />
          <motion.div
            initial="hidden"
            animate={loaded ? 'visible' : 'hidden'}
            variants={firstImageVariants}
            transition={{ duration: 1.5, ease: [0.175, 0.885, 0.32, 1.275] }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            className="absolute lg:-top-10 lg:left-20 top-10 left-0"
          >
            <Image
              alt="agora-patterns"
              src={`${IMAGE_CDN_URL}/landing/agora-patterns.png` || ''}
              width={isMobile ? 200 : 270}
              height={isMobile ? 170 : 240}
            />
          </motion.div>
          <motion.div
            initial="hidden"
            animate={loaded ? 'visible' : 'hidden'}
            variants={secondImageVariants}
            transition={{ duration: 1.5, ease: [0.175, 0.885, 0.32, 1.275] }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            className="absolute lg:-bottom-10 lg:right-20 bottom-10 right-0"
          >
            <Image
              alt="agora-vendor-avatar"
              src={`${IMAGE_CDN_URL}/landing/agora-vendor-avatar.png` || ''}
              width={isMobile ? 210 : 280}
              height={isMobile ? 150 : 220}
            />
          </motion.div>
        </>
      );
    case 1:
      return (
        <>
          <Circle />
          <InnerCircle />
          <motion.div
            initial="hidden"
            animate={{ x: '-20px', opacity: loaded ? 1 : 0 }}
            transition={{ duration: 1 }}
            variants={box1Variants}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            className="absolute z-10 lg:top-0 lg:left-20 top-5 left-10"
          >
            <BoxItem
              title={items[0].title}
              location={items[0].location}
              color="red"
              isLocal={items[0].isLocal}
            />
          </motion.div>
          <motion.div
            initial="hidden"
            animate={{ x: '20px', opacity: loaded ? 1 : 0 }}
            variants={box2Variants}
            transition={{ duration: 1 }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            className="absolute z-10 lg:right-20 right-10"
          >
            <BoxItem
              title={items[1].title}
              location={items[1].location}
              color="green"
              isLocal={items[1].isLocal}
            />
          </motion.div>

          <motion.div
            initial="hidden"
            animate={{ x: '-20px', opacity: loaded ? 1 : 0 }}
            variants={box3Variants}
            transition={{ duration: 1 }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            className="absolute z-10 lg:bottom-4 lg:left-20 bottom-5 left-10"
          >
            <BoxItem
              title={items[2].title}
              location={items[2].location}
              color="yellow"
              isLocal={items[2].isLocal}
            />
          </motion.div>
          <motion.div
            initial="hidden"
            animate={loaded ? 'visible' : 'hidden'}
            variants={calendarVariants}
            transition={{ duration: 1, ease: [0.175, 0.885, 0.32, 1.275] }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            className="absolute"
          >
            <Image
              alt="agora-mini-calendar"
              src={`${IMAGE_CDN_URL}/landing/agora-mini-calendar.png` || ''}
              width={isMobile ? 250 : 410}
              height={isMobile ? 162 : 322}
            />
          </motion.div>
        </>
      );
    default:
      return (
        <>
          <Circle />
          <InnerCircle />
          <motion.div
            initial="hidden"
            animate={loaded ? 'visible' : 'hidden'}
            variants={{
              hidden: { scale: 0, opacity: 0 },
              visible: { scale: 1, opacity: 1 }
            }}
            transition={{ duration: 1.5, ease: [0.175, 0.885, 0.32, 1.275] }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            className="absolute lg:-top-10 lg:left-10 top-5 left-0"
          >
            <Image
              alt="agora-vendor-instagram"
              src={`${IMAGE_CDN_URL}/landing/agora-vendor-instagram.png` || ''}
              width={isMobile ? 220 : 350}
              height={isMobile ? 120 : 200}
            />
          </motion.div>
          <motion.div
            initial="hidden"
            animate={loaded ? 'visible' : 'hidden'}
            variants={{
              hidden: { scale: 0, opacity: 0 },
              visible: { scale: 1, opacity: 1 }
            }}
            transition={{ duration: 1.5, ease: [0.175, 0.885, 0.32, 1.275] }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            className="absolute lg:right-14 lg:bottom-14 right-0 bottom-14"
          >
            <Image
              alt="agora-message-1"
              src={`${IMAGE_CDN_URL}/landing/agora-message-1.webp` || ''}
              width={isMobile ? 180 : 300}
              height={isMobile ? 100 : 150}
            />
          </motion.div>
          <motion.div
            initial="hidden"
            animate={loaded ? 'visible' : 'hidden'}
            variants={{
              hidden: { scale: 0, opacity: 0 },
              visible: { scale: 1, opacity: 1 }
            }}
            transition={{ duration: 1.5, ease: [0.175, 0.885, 0.32, 1.275] }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            className="absolute lg:bottom-0 lg:right-32 bottom-5 right-20"
          >
            <Image
              alt="agora-message-2"
              src={`${IMAGE_CDN_URL}/landing/agora-message-2.webp` || ''}
              width={isMobile ? 180 : 300}
              height={isMobile ? 61 : 80}
            />
          </motion.div>

          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: loaded ? 1 : 0, opacity: loaded ? 1 : 0 }}
            transition={{ duration: 1, ease: [0.175, 0.885, 0.32, 1.275] }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            className="absolute lg:-bottom-20 -bottom-5"
          >
            <Image
              alt="agora-whatsapp"
              src={`${IMAGE_CDN_URL}/landing/agora-whatsapp.png` || ''}
              width={isMobile ? 60 : 80}
              height={isMobile ? 60 : 80}
            />
          </motion.div>

          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: loaded ? 1 : 0, opacity: loaded ? 1 : 0 }}
            transition={{ duration: 1, ease: [0.175, 0.885, 0.32, 1.275] }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            className="absolute lg:top-0 lg:right-24 top-20 right-0"
          >
            <Image
              alt="agora-facebook"
              src={`${IMAGE_CDN_URL}/landing/agora-facebook.png` || ''}
              width={isMobile ? 70 : 80}
              height={isMobile ? 70 : 80}
            />
          </motion.div>

          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: loaded ? 1 : 0, opacity: loaded ? 1 : 0 }}
            transition={{ duration: 1, ease: [0.175, 0.885, 0.32, 1.275] }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            className="absolute lg:left-20 left-0 bottom-24"
          >
            <Image
              alt="agora-instagram"
              src={`${IMAGE_CDN_URL}/landing/agora-instagram.png` || ''}
              width={isMobile ? 70 : 80}
              height={isMobile ? 70 : 80}
            />
          </motion.div>
        </>
      );
  }
};
